// src/pages/LoginPage.js
import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "../../assets/global/Theme-variable";
import Footer from "../../layouts/FullLayout/Footer/Footer";
import { loginUser } from "../../services/userAuthService";
import loginBg from "../../assets/images/backgrounds/login-bg.png";
import { CheckCircleOutline } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";


const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({});

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const { login } = useAuth();
  let navigate = useNavigate();


  const validateForm = () => {
    const errors = { email: "", password: "" };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      errors.email = "Please enter a valid email";
    }
    if (!password || password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setSnackbarOpen(false);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const loggedInUser = await loginUser(email, password);
        console.log("loggedInUser", loggedInUser);
        login(loggedInUser); // Save logged in user to context and localStorage
        navigate('/config/botconfig');
      } catch (error) {
        console.error("Login error", error);
        setSnackbarConfig({
          message: "Login failed. Please try again!",
          severity: "error",
        });
      }
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={baseTheme}>
      <Helmet>
        <title>EZAffliate - Login Page</title>
        <meta name="description" content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to login to your account." />
      </Helmet>
      <Container
        component="main"
        sx={{
          mt: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "auto",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Card variant="outlined" sx={{ p: 0 }}>
          <Box sx={{ padding: "15px 30px" }} display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                SIGN IN
              </Typography>
              {(formErrors.email || formErrors.password) && (
                <Alert
                  icon={<CheckCircleOutline fontSize="inherit" />}
                  severity="error"
                  sx={{ marginTop: "10px" }}
                >
                  {formErrors.email || formErrors.password}
                </Alert>
              )}
            </Box>
          </Box>
          <Divider />
          <CardContent sx={{ padding: "30px" }}>
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                variant="outlined"
                error={!!formErrors.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={formErrors.email}
              />
              <TextField
                variant="outlined"
                error={!!formErrors.password}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                helperText={formErrors.password}
              />
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Sign In
              </Button>

              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                sx={{ mt: 2 }}
              >

                <a
                  href="/register"
                  style={{ textDecoration: "none", color: "#1976d2" }} >
                  {"Don't have an account? "}
                </a>
              </Typography>

            </Box>
          </CardContent>
        </Card>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>

      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default LoginPage;
