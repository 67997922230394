import React from "react";

import { Grid } from "@mui/material";
import MessageConverterForm from "../forms/MessageConverterForm";
import { Helmet } from "react-helmet";

const MessageConverter = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Message Converter</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to convert your message links to your affiliate links."
          /> 
        </Helmet>
        <MessageConverterForm />
      </Grid>
    </Grid>
  );
};

export default MessageConverter;
