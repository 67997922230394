import React from "react";

import { Grid } from "@mui/material";
import BotConfigForm from "../forms/BotConfigForm";
import { Helmet } from "react-helmet";

const BotConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Bot Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the Telegram bot where you will send the message and will get the converted message with your affilate link."
          />
        </Helmet>
        <BotConfigForm autoFocus />
      </Grid>
    </Grid>
  );
};

export default BotConfig;
