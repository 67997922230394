import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Chip,
} from "@mui/material";

const BaseCard = (props) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 0,
      }}
    >
      <Box p={2} display="flex" alignItems="center">
        <Box>
          <Typography variant="h4">{props.title}</Typography>
          {props.helpText ? (
            props.helpText.split('\n').map((line, index) => (
              <Typography key={index} sx={{ mt: 2 }} variant="body2">
                {line}
              </Typography>
            ))
          ) : (
            ""
          )}
          {props.urlTitle ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2 }}
            >
              <a
                href={props.url} target="_blank" rel="noreferrer"
                style={{ textDecoration: "none", color: "#1976d2" }} >
                {props.urlTitle}
              </a>
            </Typography>
          ) : (
            ""
          )}
        </Box>
        {props.chiptitle ? getChipContent(props) : ""}
      </Box>
      <Divider />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

function getChipContent(props) {
  return (
    <Chip
      label={props.chiptitle}
      size="small"
      sx={{
        ml: "auto",
        fontSize: "12px",
        fontWeight: "500",
      }}
    />
  );
}

export default BaseCard;