import React, { useEffect, useState } from "react";
import {
    CardContent, TextField,
    Button,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Typography,
    FormControlLabel,
    Switch,
    RadioGroup,
    Radio
} from "@mui/material";
import { updateFlipkartConfig } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";

const FlipkartConfigForm = () => {
    const [flipkartTrackingId, setFlipkartTrackingId] = useState("");
    const [flipkartExtraParam, setFlipkartExtraParam] = useState("");
    const [generateFlipkartDLLinks, setGenerateFlipkartDLLinks] = useState(false);
    const [shortningNeeded, setShortningNeeded] = useState(false);
    const [linkShortnerType, setLinkShortnerType] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const [formErrors, setFormErrors] = useState({});

    let currentUser = JSON.parse(localStorage.getItem('user'));

    const validateForm = () => {
        const errors = { flipkartTrackingId: "" };
        if (!flipkartTrackingId) {
            errors.flipkartTrackingId = "Please enter a valid Flipkart Tracking ID";
        }
        setFormErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        console.log("Flipkart Tracking ID:", flipkartTrackingId);
        console.log("Flipkart Extra Param:", flipkartExtraParam);
        console.log("Generate Flipkart DL Links:", generateFlipkartDLLinks);
        const snackbar = {
            message: "",
            severity: "",
        };
        try {
            const response = await updateFlipkartConfig({ flipkartTrackingId, flipkartExtraParam, generateFlipkartDLLinks, shortningNeeded, linkShortnerType });
            snackbar.message = response.message;
            snackbar.severity = "success";
        } catch (error) {
            snackbar.message = error.message;
            snackbar.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbar);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeComponent = () => {
        // console.log("Current User:", currentUser);
        if (currentUser && currentUser.flipkartConfiguration) {
            setFlipkartTrackingId(currentUser.flipkartConfiguration.flipkartTrackingId || "");
            setFlipkartExtraParam(currentUser.flipkartConfiguration.flipkartExtraParam || "");
            setGenerateFlipkartDLLinks(currentUser.flipkartConfiguration.generateFlipkartDLLinks || false);
            setShortningNeeded(currentUser.flipkartConfiguration.shortningNeeded || false);
            setLinkShortnerType(currentUser.flipkartConfiguration.linkShortnerType || undefined);
        }
    };

    return (
        <div>
            <BaseCard title="Flipkart Configuration"
                urlTitle= "How to setup Flipkart Configuration?"
                url="https://ezaffiliate.in/docs/quick-setup/setting-up-flipkart-affiliate-tracking-id/"
            >
                <CardContent sx={{ padding: "30px" }}>
                    <form>
                        <TextField
                            id="flipkartTrackingId"
                            type="text"
                            error={Boolean(formErrors.flipkartTrackingId)}
                            helperText={formErrors.flipkartTrackingId}
                            name="flipkartTrackingId"
                            label="Flipkart Tracking ID"
                            variant="outlined"
                            value={flipkartTrackingId}
                            fullWidth
                            onChange={(e) => setFlipkartTrackingId(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            id="flipkartExtraParam"
                            type="text"
                            name="flipkartExtraParam"
                            label="Flipkart Extra Param (optional)"
                            variant="outlined"
                            value={flipkartExtraParam}
                            fullWidth
                            onChange={(e) => setFlipkartExtraParam(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={shortningNeeded}
                                        onChange={(e) => setShortningNeeded(e.target.checked)}
                                    />
                                }
                                label="Shortening Needed"
                            />
                            {shortningNeeded && (
                                <>
                                    <Typography variant="body2">
                                        Select Link Shortner
                                    </Typography>
                                    <RadioGroup
                                        value={linkShortnerType}
                                        onChange={(e) => setLinkShortnerType(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value={'bitly'}
                                            control={<Radio />}
                                            label="Bitly"
                                            defaultChecked
                                        />
                                    </RadioGroup>
                                </>
                            )}

                        </div>

                        <Button
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            Submit
                        </Button>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default FlipkartConfigForm;
