import React, { useEffect, useState } from "react";
import {
    CardContent, Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Grid,
    Divider
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateBitlyTokens } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";

const BitlyConfigForm = () => {
    const [bitlyTokens, setBitlyTokens] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});

    let currentUser = JSON.parse(localStorage.getItem("user"));

    const handleTokenChange = (index, value) => {
        const newTokens = [...bitlyTokens];
        newTokens[index] = value;
        setBitlyTokens(newTokens);
    };

    const handleAddToken = () => {
        setBitlyTokens([...bitlyTokens, ""]);
    };

    const handleRemoveToken = (index) => {
        const newTokens = [...bitlyTokens];
        newTokens.splice(index, 1);
        setBitlyTokens(newTokens);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await updateBitlyTokens(bitlyTokens);
            snackbarConfig.message = response.message;
            snackbarConfig.severity = "success";
        } catch (error) {
            snackbarConfig.message = error.message;
            snackbarConfig.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbarConfig);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeComponent = () => {
        // console.log("Current User:", currentUser);
        if (currentUser && currentUser.bitlyTokens) {
            setBitlyTokens(currentUser.bitlyTokens);
        }
    };

    return (
        <div>
            <BaseCard title="Bitly Tokens"
                helpText="Enter your Bitly Tokens here"
                urlTitle="How to get Bitly Tokens?"
                url="https://dev.bitly.com/"
            >
                <CardContent sx={{ padding: "30px" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                        Total Number of Tokens: {bitlyTokens.length}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <List>
                            {bitlyTokens.map((token, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemoveToken(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText>
                                        <TextField
                                            type="text"
                                            label={`Token ${index + 1}`}
                                            variant="outlined"
                                            value={token}
                                            fullWidth
                                            onChange={(e) => handleTokenChange(index, e.target.value)}
                                        />
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleAddToken}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                >
                                    Add Token
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BitlyConfigForm;
