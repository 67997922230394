import React from "react";

import { Grid } from "@mui/material";
import FlipkartConfigForm from "../forms/FlipkartConfigForm";
import { Helmet } from "react-helmet";

const FlipkartConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Flipkart Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the Flipkart affiliate configc by which you can create your flipkart affiliate links and send them on telegram in automated way."
          />
        </Helmet>
        <FlipkartConfigForm />
      </Grid>
    </Grid>
  );
};

export default FlipkartConfig;
