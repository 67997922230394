import React, { useContext, useState, useEffect, useCallback } from 'react';

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const localUser = localStorage.getItem('user');
  const [user, setUser] = useState(localUser && localUser !== 'undefined' ? JSON.parse(localUser) : null);
  const [timeoutId, setTimeoutId] = useState(null);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    resetSessionTimeout();
  };

  const logout = useCallback(() => {
    console.log('Logging out');
    setUser(null);
    localStorage.removeItem('user');
    if (timeoutId) clearTimeout(timeoutId);
  }, [timeoutId]);

  const resetSessionTimeout = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
    const id = setTimeout(() => logout(), 30 * 60 * 1000); // 30 minutes inactivity timeout
    setTimeoutId(id);
  }, [logout, timeoutId]);

  useEffect(() => {
    window.addEventListener('mousemove', resetSessionTimeout);
    return () => window.removeEventListener('mousemove', resetSessionTimeout);
  }, [resetSessionTimeout]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
