import React from "react";

import { Grid } from "@mui/material";
import LogoConfigForm from "../forms/LogoConfigForm";
import { Helmet } from "react-helmet";

const LogoConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Logo Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the logo by which you can use to watermark images in automated way." 
          />
        </Helmet>
        <LogoConfigForm />
      </Grid>
    </Grid>
  );
};

export default LogoConfig;
