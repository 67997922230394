import axios from "axios";
import { setToken } from "./tokenService";

const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVICE_URL;

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/auth/login`, {
      email,
      password,
    });
    console.log("Response:", response.data);
    setToken(response.data.token);
    return response.data.user;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const registerUser = async (formFields) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/auth/register`, {
      ...formFields
    });
    console.log("Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}