import React from "react";

import { Avatar, Box, Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';


import userimg from "../../../assets/images/users/8.jpg";
import { useAuth } from "../../../contexts/AuthContext";


const ProfileDropdown = () => {
    
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const { logout } = useAuth();

    const handleClick4 = (event) => {
        setAnchorEl4(event.currentTarget);
      };

    const handleClose4 = () => {
        setAnchorEl4(null);
      };

    return (
        <>
            <Button
                aria-label="menu"
                color="inherit"
                aria-controls="profile-menu"
                aria-haspopup="true"
                onClick={handleClick4}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Avatar
                        src={userimg}
                        alt={userimg}
                        sx={{
                            width: "30px",
                            height: "30px",
                        }}
                    />
                </Box>
            </Button>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl4}
                keepMounted
                open={Boolean(anchorEl4)}
                onClose={handleClose4}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                sx={{
                    "& .MuiMenu-paper": {
                        width: "250px",
                        right: 0,
                        top: "70px !important",
                    },
                }}
            >
                {/* <MenuItem onClick={handleClose4}>
                    <Avatar
                        sx={{
                            width: "35px",
                            height: "35px",
                        }}
                    />
                    <Box
                        sx={{
                            ml: 2,
                        }}
                    >
                        My account
                    </Box>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose4}>
                    <ListItemIcon>
                        <PersonAddOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem onClick={handleClose4}>
                    <ListItemIcon>
                        <SettingsOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <LogoutOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )

}

export default ProfileDropdown;