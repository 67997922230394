import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_SERVICE_URL;

export const getConvertedLink = async (messageToConvert) => {
    const currentUser = JSON.parse(localStorage.getItem("user"));

    try {
        const payload = {
            email: currentUser.email,
            message: messageToConvert,
        };
        const response = await axios.post(
            `${BACKEND_URL}/api/features/convertMessage`,
            payload
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
