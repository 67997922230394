// src/pages/RegisterPage.js
import React, { useState } from "react";
import {
    Button,
    TextField,
    Container,
    Typography,
    Box, CardContent, Alert,
    Backdrop,
    CircularProgress,
    Snackbar
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "../../assets/global/Theme-variable";
import loginBg from "../../assets/images/backgrounds/login-bg.png";
import Footer from "../../layouts/FullLayout/Footer/Footer";
import { registerUser } from "../../services/userAuthService";
import { useNavigate } from "react-router-dom";
import BaseCard from "../../components/BaseCard/BaseCard";
import { Helmet } from "react-helmet";

const RegisterPage = () => {
    let navigate = useNavigate();

    const initialFormFields = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
    };

    const [formFields, setFormFields] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value });
    };

    const validateForm = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // Minimum eight characters, at least one letter and one number

        if (!formFields.firstname.trim()) {
            errors.firstname = "Firstname is required";
        }
        if (!formFields.phoneNumber.trim()) {
            errors.phoneNumber = "Phone number is required";
        }
        if (!formFields.email || !emailRegex.test(formFields.email)) {
            errors.email = "Please enter a valid email";
        }
        if (!formFields.password || !passwordRegex.test(formFields.password)) {
            errors.password =
                "Password must be at least 8 characters long and include at least one letter and one number and no spaces or special characters";
        }
        if (!formFields.confirmPassword.trim()) {
            errors.confirmPassword = "Confirm Password is required";
        } else if (formFields.password !== formFields.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const clearForm = () => {
        setFormFields(initialFormFields); // Reset form fields to initial state
        setFormErrors({}); // Optionally clear any form errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const snackbar = { message: "", severity: "" };
        if (validateForm()) {
            const formFieldsToSubmit = { ...formFields, confirmPassword: undefined };
            console.log("Form is valid:", formFields);
            const response = await registerUser(formFieldsToSubmit);
            console.log("Response:", response);
            if (response) {
                snackbar.message = "User registered successfully";
                snackbar.severity = "success";
                clearForm();
            } else {
                snackbar.message =
                    "User registration failed. Please try login with same email!";
                snackbar.severity = "error";
            }
            setSnackbarConfig(snackbar);
            setSnackbarOpen(true);
        } else {
            console.log("Validation failed");
        }
        setIsLoading(false);
    };

    const goToLogin = () => {
        navigate("/login");
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    //   const snackbarAction = (
    //     <React.Fragment>
    //       <Button color="secondary" size="small" onClick={goToLogin}>
    //         Login
    //       </Button>
    //       <IconButton
    //         size="small"
    //         aria-label="close"
    //         color="inherit"
    //         onClick={handleSnackbarClose}
    //       >
    //         <CloseOutlined fontSize="small" />
    //       </IconButton>
    //     </React.Fragment>
    //   );

    return (
        <ThemeProvider theme={baseTheme}>
            <Helmet>
                <title>EZAffiliate - Register</title>
                <meta
                    name="description"
                    content="Register with EZAffiliate"
                />
            </Helmet>   
            { }
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container
                component="main"
                maxWidth="md"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    backgroundImage: `url(${loginBg})`,
                    backgroundSize: "auto",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <BaseCard title="Register">

                    <CardContent sx={{ padding: "30px" }}>
                        <Box component="form" noValidate onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstname"
                                label="Firstname"
                                name="firstname"
                                autoComplete="fname"
                                value={formFields.firstname}
                                onChange={handleChange}
                                error={!!formErrors.firstname}
                                helperText={formErrors.firstname || ""}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="lastname"
                                label="Lastname"
                                name="lastname"
                                autoComplete="lname"
                                value={formFields.lastname}
                                onChange={handleChange}
                                error={!!formErrors.lastname}
                                helperText={formErrors.lastname || ""}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formFields.email}
                                onChange={handleChange}
                                error={!!formErrors.email}
                                helperText={formErrors.email || ""}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Phone Number"
                                name="phoneNumber"
                                autoComplete="tel"
                                value={formFields.phoneNumber}
                                onChange={handleChange}
                                error={!!formErrors.phoneNumber}
                                helperText={formErrors.phoneNumber || ""}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formFields.password}
                                onChange={handleChange}
                                error={!!formErrors.password}
                                helperText={formErrors.password || ""}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={formFields.confirmPassword}
                                onChange={handleChange}
                                error={!!formErrors.confirmPassword}
                                helperText={formErrors.confirmPassword || ""}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Register
                            </Button>

                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ mt: 2 }}
                            >

                                <a
                                    href="/login"
                                    style={{ textDecoration: "none", color: "#1976d2" }} >
                                    {"Already have an account? Sign In"}
                                </a>
                            </Typography>


                        </Box>
                    </CardContent>
                </BaseCard>
                
            </Container>
            <Footer />
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                    <Button
                        color="secondary"
                        size="small"
                        onClick={goToLogin}
                        sx={{ ml: 2 }}
                    >
                        Login
                    </Button>
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default RegisterPage;
