import React, { useState } from 'react';
import {
  CardContent,
  TextField,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import BaseCard from '../../components/BaseCard/BaseCard';
import { getConvertedLink } from '../../services/botFeatureService';

const MessageConverterForm = () => {
  const [message, setMessage] = useState('');
  const [convertedMessage, setConvertedMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({});

  const handleConvert = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await getConvertedLink(message);
      setConvertedMessage(response.message);
      setSnackbarConfig({ message: 'Message converted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error:', error);
      setSnackbarConfig({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(convertedMessage);
    setSnackbarConfig({ message: 'Copied to clipboard!', severity: 'success' });
    setSnackbarOpen(true);
  };

  return (
    <div>
      <BaseCard title="Message Converter"
        helpText="Enter message to convert to a link."
      >
        <CardContent sx={{ padding: '30px' }}>
          <form>
            <TextField
              id="message"
              multiline={true}
              rows={4}
              type="text"
              name="message"
              label="Message"
              variant="outlined"
              value={message}
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
              sx={{ mb: 2 }}
            />

            <div>
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                onClick={handleConvert}
              >
                Convert
              </Button>
            </div>

            {convertedMessage ?
              <div style={{ marginTop: '20px' }}>
                <TextField
                  id="convertedMessage"
                  label="Converted Message"
                  variant="outlined"
                  value={convertedMessage}
                  multiline={true}
                  rows={4}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ mb: 2 }}
                />
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={copyToClipboard}
                >
                  Copy
                </Button>
              </div>
              : null}
          </form>
        </CardContent>
      </BaseCard>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
          {snackbarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MessageConverterForm;