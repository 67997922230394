import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the import path as necessary

const ProtectedRoute = () => {
  const { user } = useAuth(); // Assuming useAuth returns an object with user info

  return user && user.email ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;