import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { baseTheme } from './assets/global/Theme-variable';
import ProtectedRoute from './routes/ProtectedRoute';
import LoginPage from './views/pages/LoginPage';
import RegisterPage from './views/pages/RegisterPage';
import { AuthProvider } from './contexts/AuthContext';
import { Helmet } from 'react-helmet';
import Themeroutes from './routes/Router'; // Assuming Themeroutes is a component that returns Routes

const App = () => {
  return (
    <ThemeProvider theme={baseTheme}>
      <AuthProvider>
        <Helmet>
          <title>EZAffliate - Affiliate Marketing Dashboard</title>
          <meta name="description" content="EzAffliate offers a comprehensive affiliate marketing dashboard for free by which you can configure and create your own affiliate links." />
        </Helmet>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<Themeroutes />} />
          </Route>
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;