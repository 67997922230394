import React from "react";

import { Grid } from "@mui/material";
import AdmitadConfigForm from "../forms/AdmitadConfigForm";
import { Helmet } from "react-helmet";

const AdmitadConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Admitad Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the Admitad token by which you can short your affiliate links."
          />
        </Helmet>
        <AdmitadConfigForm />
      </Grid>
    </Grid>
  );
};

export default AdmitadConfig;
