import React, { useEffect, useState } from "react";
import {
    CardContent, Typography,
    TextField,
    Button,
    List,
    ListItem,
    IconButton,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    Grid,
    Divider
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateMessageConfig } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";

const MessageConfigForm = () => {
    const [prefix, setPrefix] = useState("");
    const [textReplacements, setTextReplacements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});

    let currentUser = JSON.parse(localStorage.getItem("user"));

    const handleReplacementChange = (index, field, value) => {
        const newTextReplacements = [...textReplacements];
        newTextReplacements[index][field] = value;
        setTextReplacements(newTextReplacements);
    };

    const handleAddReplacement = () => {
        setTextReplacements([...textReplacements, { searchValue: '', replaceValue: '' }]);
    };

    const handleRemoveReplacement = (index) => {
        const newTextReplacements = [...textReplacements];
        newTextReplacements.splice(index, 1);
        setTextReplacements(newTextReplacements);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await updateMessageConfig({ prefix, textReplacements });
            snackbarConfig.message = response.message;
            snackbarConfig.severity = "success";
        } catch (error) {
            snackbarConfig.message = error.message;
            snackbarConfig.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbarConfig);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeComponent = () => {
        // console.log("Current User:", currentUser);
        if (currentUser && currentUser.messageConfiguration) {
            setTextReplacements(currentUser.messageConfiguration.textReplacements);
            setPrefix(currentUser.messageConfiguration.prefix);
        }
    };


    return (
        <div>
            <BaseCard title="Message Configuration"
                helpText = {
                    "Prefix to Add in Each Message: This will be added to the beginning of each message.\n" +
                    "Text Replacements: This will be used to replace text in the message."
                }
            >

                <CardContent sx={{ padding: "30px" }}>

                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="prefix"
                            type="text"
                            name="prefix"
                            label="Prefix to Add in Each Message"
                            variant="outlined"
                            value={prefix}
                            fullWidth
                            onChange={(e) => setPrefix(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography sx={{ fontSize: "14px", fontWeight: "500", mb: 2 }}>
                            Total Number of Replacements: {textReplacements.length}
                        </Typography>
                        <List>
                            {textReplacements.map((replacement, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemoveReplacement(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <TextField
                                        label="Search Value"
                                        variant="outlined"
                                        value={replacement.searchValue}
                                        onChange={(e) => handleReplacementChange(index, 'searchValue', e.target.value)}
                                        sx={{ mr: 1, width: '50%' }}
                                    />
                                    <TextField
                                        label="Replace Value"
                                        variant="outlined"
                                        value={replacement.replaceValue}
                                        onChange={(e) => handleReplacementChange(index, 'replaceValue', e.target.value)}
                                        sx={{ width: '50%' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={handleAddReplacement}
                                    variant="outlined"
                                    sx={{ mb: 2, mt: 2 }}
                                >
                                    Add Replacement
                                </Button>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default MessageConfigForm;