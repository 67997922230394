import React, { useEffect, useState } from "react";
import {
    CardContent, TextField,
    Button,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    FormControlLabel,
    Switch,
    Typography,
    RadioGroup,
    Radio
} from "@mui/material";
import { updateAdmitadConfig } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";

const AdmitadConfigForm = () => {
    const [admitadClientId, setAdmitadClientId] = useState("");
    const [admitadClientSecret, setAdmitadClientSecret] = useState("");
    const [admitadAdSpaceId, setAdmitadAdSpaceId] = useState("");
    const [shortningNeeded, setShortningNeeded] = useState(false);
    const [linkShortnerType, setLinkShortnerType] = useState("");

    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const [formErrors, setFormErrors] = useState({});

    let currentUser = JSON.parse(localStorage.getItem('user'));

    const validateForm = () => {
        const errors = { admitadClientId: "", admitadClientSecret: "", admitadAdSpaceId: "" };
        if (!admitadClientId) {
            errors.admitadClientId = "Please enter a valid Admitad Client ID";
        }
        if (!admitadClientSecret) {
            errors.admitadClientSecret = "Please enter a valid Admitad Client Secret";
        }
        // if (!admitadAdSpaceId) {
        //     errors.admitadAdSpaceId = "Please enter a valid Admitad Ad Space ID";
        // }
        setFormErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        console.log("Admitad Client ID:", admitadClientId);
        console.log("Admitad Client Secret:", admitadClientSecret);
        console.log("Admitad Ad Space ID:", admitadAdSpaceId);
        const snackbar = {
            message: "",
            severity: "",
        };
        try {
            const response = await updateAdmitadConfig({ admitadClientId, admitadClientSecret, admitadAdSpaceId, shortningNeeded, linkShortnerType });
            snackbar.message = response.message;
            snackbar.severity = "success";
        } catch (error) {
            snackbar.message = error.message;
            snackbar.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbar);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const initializeComponent = () => {
        // console.log("Current User:", currentUser);
        if (currentUser && currentUser.admitadConfiguration) {
            setAdmitadClientId(currentUser.admitadConfiguration.admitadClientId || "");
            setAdmitadClientSecret(currentUser.admitadConfiguration.admitadClientSecret || "");
            setAdmitadAdSpaceId(currentUser.admitadConfiguration.admitadAdSpaceId || "");
            setShortningNeeded(currentUser.admitadConfiguration.shortningNeeded || false);
            setLinkShortnerType(currentUser.admitadConfiguration.linkShortnerType || undefined);
        }
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>
            <BaseCard title="Admitad Configuration" variant="outlined">

                <CardContent sx={{ padding: "30px" }}>
                    <form>
                        <TextField
                            id="admitadClientId"
                            type="text"
                            error={Boolean(formErrors.admitadClientId)}
                            helperText={formErrors.admitadClientId}
                            name="admitadClientId"
                            label="Admitad Client ID"
                            variant="outlined"
                            value={admitadClientId}
                            fullWidth
                            onChange={(e) => setAdmitadClientId(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            id="admitadClientSecret"
                            type="text"
                            error={Boolean(formErrors.admitadClientSecret)}
                            helperText={formErrors.admitadClientSecret}
                            name="admitadClientSecret"
                            label="Admitad Client Secret"
                            variant="outlined"
                            value={admitadClientSecret}
                            fullWidth
                            onChange={(e) => setAdmitadClientSecret(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            id="admitadAdSpaceId"
                            type="text"
                            error={Boolean(formErrors.admitadAdSpaceId)}
                            helperText={formErrors.admitadAdSpaceId}
                            name="admitadAdSpaceId"
                            label="Admitad Ad Space ID (optional)"
                            variant="outlined"
                            value={admitadAdSpaceId}
                            fullWidth
                            onChange={(e) => setAdmitadAdSpaceId(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={shortningNeeded}
                                        onChange={(e) => setShortningNeeded(e.target.checked)}
                                    />
                                }
                                label="Shortening Needed"
                            />
                            {shortningNeeded && (
                                <>
                                    <Typography variant="body2">
                                        Select Link Shortner
                                    </Typography>
                                    <RadioGroup
                                        value={linkShortnerType}
                                        onChange={(e) => setLinkShortnerType(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value={'bitly'}
                                            control={<Radio />}
                                            label="Bitly"
                                            defaultChecked
                                        />
                                    </RadioGroup>
                                </>
                            )}

                        </div>

                        <Button
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            Submit
                        </Button>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default AdmitadConfigForm;