import React from "react";

import { Grid } from "@mui/material";
import BitlyConfigForm from "../forms/BitlyConfigForm";
import { Helmet } from "react-helmet";

const BitlyConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Bitly Configuration</title>
          <meta name="description" content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the Bitly token by which you can short your affiliate links." />
        </Helmet>
        <BitlyConfigForm />
      </Grid>
    </Grid>
  );
};

export default BitlyConfig;
