import { LogoDevOutlined, MessageOutlined, SmartToyOutlined } from '@mui/icons-material';
import { FaAmazon } from 'react-icons/fa';
import { PiSwapBold } from 'react-icons/pi';
import { SiBitly, SiFlipkart } from 'react-icons/si';
import { TbHexagonLetterAFilled } from 'react-icons/tb';

const Menuitems = [
  // {
  //   title: "Dashboard",
  //   icon: DashboardOutlinedIcon,
  //   href: "/dashboards/dashboard1",
  // },
  {
    title: "Bot Configuration",
    icon: SmartToyOutlined,
    href: "/config/botConfig",
  },
  {
    title: "Message Converter",
    icon: PiSwapBold,
    href: "/feature/convertMessage",
  },
  {
    title: "Message Configuration",
    icon: MessageOutlined,
    href: "/config/messageConfig",
  },
  {
    title: "Amazon Configuration",
    icon: FaAmazon,
    href: "/config/amazonConfig",
  },
  {
    title: "Flipkart Configuration",
    icon: SiFlipkart,
    href: "/config/flipkartConfig",
  },
  {
    title: "Admitad Configuration",
    icon: TbHexagonLetterAFilled,
    href: "/config/admitadConfig",
  },
  {
    title: "Bitly Configuration",
    icon: SiBitly,
    href: "/config/bitlyConfig",
  },
  // {
  //   title: "Text Replacement Configuration",
  //   icon: TbReplace,
  //   href: "/config/textReplacementConfig",
  // },
  {
    title: "Logo Configuration",
    icon: LogoDevOutlined,
    href: "/config/logoConfig",
  },
  
  // {
  //   title: "Autocomplete",
  //   icon: AddToPhotosOutlinedIcon,
  //   href: "/form-elements/autocomplete",
  // },
  // {
  //   title: "Buttons",
  //   icon: AspectRatioOutlinedIcon,
  //   href: "/form-elements/button",
  // },
  // {
  //   title: "Checkbox",
  //   icon: AssignmentTurnedInOutlinedIcon,
  //   href: "/form-elements/checkbox",
  // },
  // {
  //   title: "Radio",
  //   icon: AlbumOutlinedIcon,
  //   href: "/form-elements/radio",
  // },
  // {
  //   title: "Slider",
  //   icon: SwitchCameraOutlinedIcon,
  //   href: "/form-elements/slider",
  // },
  // {
  //   title: "Switch",
  //   icon: SwitchLeftOutlinedIcon,
  //   href: "/form-elements/switch",
  // },
  // {
  //   title: "Form",
  //   icon: DescriptionOutlinedIcon,
  //   href: "/form-layouts/form-layouts",
  // },
  // {
  //   title: "Table",
  //   icon: AutoAwesomeMosaicOutlinedIcon,
  //   href: "/tables/basic-table",
  // },
];

export default Menuitems;
