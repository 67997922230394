import React from "react";

import { Grid } from "@mui/material";
import MessageConfigForm from "../forms/MessageConfigForm";
import { Helmet } from "react-helmet";

const MessageConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Message Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the message prefix and message replacements by which you can create your affiliate links and send them on telegram in automated way."
          />
        </Helmet>
        <MessageConfigForm />
      </Grid>
    </Grid>
  );
};

export default MessageConfig;
