import React, { useEffect, useState } from "react";
import {
    CardContent, TextField,
    Button,
    Snackbar,
    Alert,
    Backdrop,
    CircularProgress,
    FormControlLabel,
    Switch,
    Typography,
    Modal
} from "@mui/material";
import { updateAmazonConfig } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";
import { RadioGroup, Radio } from '@mui/material';
import { FaInfoCircle, FaTimes } from "react-icons/fa";


const AmazonConfigForm = () => {
    const [amazonTrackingId, setAmazonTrackingId] = useState("");
    const [amazonExtraParam, setAmazonExtraParam] = useState("");
    const [generateAmazonMasterLinksWhenShortenFails, setGenerateAmazonMasterLinksWhenShortenFails] = useState(false);
    const [shortningNeeded, setShortningNeeded] = useState(false);
    const [linkShortnerType, setLinkShortnerType] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    let currentUser = JSON.parse(localStorage.getItem('user'));

    const validateForm = () => {
        const errors = { amazonTrackingId: "" };
        if (!amazonTrackingId) {
            errors.amazonTrackingId = "Please enter a valid Amazon Tracking ID";
        }
        setFormErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        console.log("Amazon Tracking ID:", amazonTrackingId);
        console.log("Amazon Extra Param:", amazonExtraParam);
        const snackbar = {
            message: "",
            severity: "",
        };
        try {
            const response = await updateAmazonConfig({ amazonTrackingId, amazonExtraParam, generateAmazonMasterLinksWhenShortenFails, shortningNeeded, linkShortnerType });
            snackbar.message = response.message;
            snackbar.severity = "success";
        } catch (error) {
            snackbar.message = error.message;
            snackbar.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbar);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const initializeComponent = () => {
        // console.log("Current User:", currentUser);
        if (currentUser && currentUser.amazonConfiguration) {
            setAmazonTrackingId(currentUser.amazonConfiguration.amazonTrackingId);
            setAmazonExtraParam(currentUser.amazonConfiguration.amazonExtraParam);
            setGenerateAmazonMasterLinksWhenShortenFails(currentUser.amazonConfiguration.generateAmazonMasterLinksWhenShortenFails);
            setShortningNeeded(currentUser.amazonConfiguration.shortningNeeded);
            setLinkShortnerType(currentUser.amazonConfiguration.linkShortnerType);
        }
    };

    useEffect(() => {
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInfoIconClick = (event) => {
        event.preventDefault();
        setInfoModalOpen(true);
    };

    const handleCloseInfoModal = () => {
        setInfoModalOpen(false);
    };



    return (
        <div>
            <BaseCard title="Amazon Configurations"
                urlTitle="How to setup Amazon Configurations?"
                url="https://ezaffiliate.in/docs/quick-setup/setting-up-amazon-affiliate-tracking-id/"
            >
                <CardContent sx={{ padding: "30px" }}>
                    <form>
                        <TextField
                            id="amazonTrackingId"
                            type="text"
                            error={Boolean(formErrors.amazonTrackingId)}
                            helperText={formErrors.amazonTrackingId}
                            name="amazonTrackingId"
                            label="Amazon Tracking ID"
                            variant="outlined"
                            value={amazonTrackingId}
                            fullWidth
                            onChange={(e) => setAmazonTrackingId(e.target.value)}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            id="amazonExtraParam"
                            type="text"
                            name="amazonExtraParam"
                            label="Amazon Extra Param (optional)"
                            variant="outlined"
                            value={amazonExtraParam}
                            fullWidth
                            onChange={(e) => setAmazonExtraParam(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={generateAmazonMasterLinksWhenShortenFails}
                                    onChange={(e) => setGenerateAmazonMasterLinksWhenShortenFails(e.target.checked)}
                                />
                            }
                            label={
                                <div>
                                    Generate Amazon Master Links When Shorten Fails
                                    <FaInfoCircle onClick={handleInfoIconClick} style={{ marginLeft: 8, cursor: 'pointer' }} />
                                </div>
                            }
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={shortningNeeded}
                                        onChange={(e) => setShortningNeeded(e.target.checked)}
                                    />
                                }
                                label="Shortening Needed"
                            />
                            {shortningNeeded && (
                                <>
                                    <Typography variant="body2">
                                        Select Link Shortner
                                    </Typography>
                                    <RadioGroup
                                        value={linkShortnerType}
                                        onChange={(e) => setLinkShortnerType(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value={'bitly'}
                                            control={<Radio />}
                                            label="Bitly"
                                            defaultChecked
                                        />
                                    </RadioGroup>
                                </>
                            )}


                        </div>

                        <Button
                            type="submit"
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            Submit
                        </Button>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
            <Modal
                open={infoModalOpen}
                onClose={handleCloseInfoModal}
                aria-labelledby="info-modal-title"
                aria-describedby="info-modal-description"
            >
                <div style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: 24, width: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="info-modal-title" variant="h6" component="h2">
                            Information
                        </Typography>
                        <FaTimes onClick={handleCloseInfoModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Typography id="info-modal-description" sx={{ mt: 2 }}>
                        This option will generate a search link with the same product in case we are not able to short the Amazon URL. 
                        <br/ > <br />
                        You can choose to either use bitly to short the converted affiliate link or make a seach link which will not require any bitly token.
                    </Typography>
                </div>
            </Modal>
        </div>
    );
};

export default AmazonConfigForm;
