import React, { useState, useEffect } from "react";
import {
  CardContent, Typography,
  Switch,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
  FormControlLabel, Grid
} from "@mui/material";
import { updateLogoConfiguration } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";
const LogoConfigForm = () => {

  const [logo, setLogo] = useState(null);
  const [enableLogoByDefault, setEnableLogoByDefault] = useState(false);
  const [logoPresent, setLogoPresent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({});

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = { logo: "" };

    // Check file type
    if (logo && logo.type !== 'image/jpeg' && logo.type !== 'image/png') {
      errors.logo = "Please upload a valid image file (JPG or PNG)";
    }
    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  useEffect(() => {
    // Initialize component with existing configuration if available
    const config = JSON.parse(localStorage.getItem("user")).logoConfiguration;
    if (config) {
      setEnableLogoByDefault(config.enableLogoByDefault);
      setLogo(config.logo);
      setLogoPresent(true);
    }
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setFormErrors({ logo: "" });
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        logo: "Please upload a valid image file (JPG or PNG)",
      }));
      return;
    } else if (file && file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        logo: "File size exceeds 1MB",
      }));
      return;
    }
    setLogo(file);
    setLogoPresent(true);
  };

  const handleSwitchChange = (event) => {
    setEnableLogoByDefault(event.target.checked);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      // Convert logo to Buffer or handle as needed for your backend
      //const logoBuffer = await convertFileToBuffer(logo);
      const response = await updateLogoConfiguration(enableLogoByDefault, logo);
      snackbarConfig.message = response.message;
      snackbarConfig.severity = "success";
    } catch (error) {
      snackbarConfig.message = error.message;
      snackbarConfig.severity = "error";
      console.error("Error:", error);
    }
    setSnackbarConfig(snackbarConfig);
    setSnackbarOpen(true);
    setLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <BaseCard title="Logo Configuration"
        helpText="Configure the logo for your bot!"
      >
        <CardContent sx={{ padding: "30px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {logoPresent && (
                <>
                  <Alert severity="info">
                    <Typography variant="body1" gutterBottom>
                      <b>Note:</b> A logo is currently configured.
                    </Typography>
                  </Alert>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              {formErrors.logo ?
                <Alert severity="error" sx={{ marginBottom: "10px" }}>
                  {formErrors.logo}
                </Alert> : null}
              <Button variant="outlined" component="label">

                {logo ? "Change Logo" : "Upload Logo"}
                <input type="file" hidden onChange={handleLogoChange} />
              </Button>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableLogoByDefault}
                    onChange={handleSwitchChange}
                  />
                }
                label="Enable Logo By Default"
              />
            </Grid>
          </Grid>
          {/* <input
                        accept="image/*"
                        type="file"
                        onChange={handleLogoChange}
                        style={{ display: 'block', margin: '20px 0' }}
                    /> */}

          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            sx={{ marginTop: "20px" }}
          >
            Submit
          </Button>
        </CardContent>
      </BaseCard>
      <Backdrop open={loading} style={{ color: "#fff", zIndex: 1200 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
          {snackbarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LogoConfigForm;
