import React from "react";
import { Box, Icon, Typography } from "@mui/material";
import { FaHeart } from "react-icons/fa";
const Footer = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
       
        <Typography variant="body2">
          {"Made with"} <Icon sx={{ color: "red"}}>
          <FaHeart size={15} />
        </Icon> {"by Shubham Arora"}
        </Typography>
        
      </Box>
    </Box>
  );
};

export default Footer;
