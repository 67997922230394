import React from "react";

import { Grid } from "@mui/material";
import AmazonConfigForm from "../forms/AmazonConfigForm";
import { Helmet } from "react-helmet";

const AmazonConfig = () => {
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <Helmet>
          <title>EZAffliate - Amazon Configuration</title>
          <meta
            name="description"
            content="With EZAffiliate you can easily create and manage your affiliate links. Use this page to configure the Amazon affiliate id by which you can short create affiliate links."
          />
        </Helmet>
        <AmazonConfigForm />
      </Grid>
    </Grid>
  );
};

export default AmazonConfig;
