import React, { useEffect, useState } from "react";

import {
    CardContent,
    TextField,
    FormControlLabel,
    Button,
    Switch,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
} from "@mui/material";
import { updateBotConfig } from "../../services/botConfigService";
import BaseCard from "../../components/BaseCard/BaseCard";


const BotConfigForm = () => {
    const [botToken, setBotToken] = useState("");
    const [botEnabled, setBotEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const [formErrors, setFormErrors] = useState({});

    let currentUser = JSON.parse(localStorage.getItem('user'));


    const isValidBotToken = (token) => {
        // Regular expression to match the Telegram bot token format
        const tokenRegex = /^[0-9]{9,10}:[a-zA-Z0-9_-]{35}$/;
        return tokenRegex.test(token);
    };

    const validateForm = () => {
        const errors = { botToken: "" };
        if (!botToken || !isValidBotToken(botToken)) {
            errors.botToken = "Please enter a valid Telegram bot token";
        }
        setFormErrors(errors);
        return Object.values(errors).every((error) => error === "");
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setLoading(true);
        console.log("Bot Token:", botToken);
        console.log("Bot Enabled:", botEnabled);
        const snackbar = {
            message: "",
            severity: "",
        }
        try {
            const response = await updateBotConfig({ botToken, botEnabled });
            snackbar.message = response.message;
            snackbar.severity = "success";
        } catch (error) {
            snackbar.message = error.message;
            snackbar.severity = "error";
            console.error("Error:", error);
        }
        setSnackbarConfig(snackbar);
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    };

    useEffect(() => {
        const initializeComponent = () => {
            if (currentUser && currentUser.botConfiguration) {
                setBotToken(currentUser.botConfiguration.botToken || "");
                setBotEnabled(currentUser.botConfiguration.botEnabled || false);
            }
        };
        initializeComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <BaseCard title="Telegram Bot Configuration"
                urlTitle="How to Get a Telegram Bot Token?"
                url="https://ezaffiliate.in/docs/quick-setup/prerequisites/how-to-obtain-a-telegram-bot-token/"
                helpText={
                    "Use this config to setup your Telegram bot. The bot will then be able to convert your links into affiliate links. \n" +
                    "Bot Token: Enter your Telegram bot token. You can get this from @BotFather.\n" + 
                    "Bot Enabled: Enable/disable the bot."
                
                }
            >
                <CardContent sx={{ padding: "30px" }}>
                    <form>
                        <TextField
                            id="bottoken"
                            type="text"
                            error={Boolean(formErrors.botToken)}
                            helperText={formErrors.botToken}
                            name="bottoken"
                            label="Bot Token"
                            variant="outlined"
                            value={botToken}
                            fullWidth
                            onChange={(e) => setBotToken(e.target.value)}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <FormControlLabel
                            control={<Switch color="primary" />}
                            label="Bot Enabled"
                            name="botEnabled"
                            id="botEnabled"
                            checked={botEnabled}
                            onChange={(e) => setBotEnabled(e.target.checked)}
                            sx={{
                                mb: 2,
                            }}
                        />

                        <div>
                            <Button
                                type="submit"
                                size="large"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </BaseCard>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarConfig.severity}>
                    {snackbarConfig.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BotConfigForm;
